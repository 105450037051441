<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span>Editar Usuario #{{user_id}}</span>
            </v-card-title>
            <v-card-text>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
                    <v-text-field
                        v-model="user.name"
                        :counter="200"
                        label="Name"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="user.email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                    ></v-text-field>
                    <b-form-group
                    description="Marca mas de 1 permiso manteniendo la tecla ctrl."
                    label="Permisos"
                    
                    >
                        <b-form-select style="height: 300px;" v-model="user.permissions" multiple class="mb-3">
                            <optgroup v-for="(attribute,i) in Object.keys(permissionsList)" :key="i" :label="attribute">
                                <option v-for="(permiso,index) in permissionsList[attribute]" :key="index" :value="permiso.id">{{permiso.name}}</option>
                            </optgroup>
                        </b-form-select>
                    </b-form-group>

                    <v-select v-if="warehhousePicking" multiple :items="warehousesList" v-model="user.warehousesSelected" label="Bodegas asociadas en la store actual ">
                    </v-select>

                     <v-select v-if="pickupPointsPicking" multiple :items="pickupPointsPList" v-model="user.pickupPointsSelected" label="Bodegas asociadas en la store actual ">
                    </v-select>

                    <v-select multiple :items="storesList" v-model="user.storesSelected" label="Stores asociadas">
                    </v-select>
                    <v-select
                    :items="user.stores"
                    v-model="user.store_id"
                    label="Store actual"
                    ></v-select>
                    <v-checkbox label="Mantener contraseña" v-model="lock_password"></v-checkbox>
                    <v-text-field type="password" :disabled="lock_password" :rules="passwordRules" :required="!lock_password" label="Nueva contraseña" v-model="user.password"></v-text-field>
                    <v-text-field type="password" :disabled="lock_password" :rules="passwordRules" :required="!lock_password" label="Confirmar contraseña" v-model="user.password_confirmation"></v-text-field>
                    <v-switch v-model="user.active" label="Activo" >

                    </v-switch>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        var vm = this;
        return {
            lock_password: true,
            valid:true,
            showDialog: false,
            permisosSelected: [],
            user_id: undefined,
            user: {
                name: '',
                email: '',
                permissions: [],
                store_id: undefined,
                stores: [],
                storesSelected: [],
                password: undefined,
                password_confirmation: undefined,
                active: true,
                warehousesSelected: [],
                pickupPointsSelected: []
            },
            select: [],
            passwordRules: [
                value => { if(vm.lock_password){ return true; }else{ return !!value || 'Requerido' } }
            ],
            emailRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email inválido.'
                },
            ],
            permissionsList: {},
            storesList: [],
            warehhousePicking : false,
            warehousesList: [],
            pickupPointsPicking : false,
            pickupPointsPList: [],
        }
    },
    created(){
        var vm = this;
        this.axios({
            url: 'admin/permissions',
            method: 'GET',
        }).then( response => {
            vm.permissionsList = response.data;
            
        }).catch( error => {
            console.log(error);
        });
        vm.warehhousePicking = false;
        vm.pickupPointsPicking = false;
        this.$parent.$on('openDialogEditarUsuario', (user_id) => {
            this.user_id = user_id;
            this.axios({
                url: 'admin/users/'+user_id+'/edit',
                method: 'GET',
            }).then( response => {
                vm.user = {...response.data, active: !!response.data.active ? true : false};
                vm.user.stores.forEach( e => {
                    let encontrado = vm.storesList.find(store => store.value == e.value);
                    if(encontrado){
                        e.text = encontrado.text;
                    }
                });
                vm.user.storesSelected = vm.user.stores.map( e => e.value);

                if( vm.user.additional_data != null &&  typeof vm.user.additional_data.picking_warehouses_id !== 'undefined'  && vm.user.additional_data.picking_warehouses_id != undefined){
                    console.log(vm.user.additional_data.picking_warehouses_id);
                    vm.user.warehousesSelected = vm.user.additional_data.picking_warehouses_id;
                    console.log(vm.user.warehousesSelected);
                }

                if( vm.user.additional_data != null &&  typeof vm.user.additional_data.picking_warehouses_id !== 'undefined'  && vm.user.additional_data.picking_warehouses_id != undefined){
                    console.log(vm.user.additional_data.picking_warehouses_id);
                    vm.user.pickupPointsSelected = vm.user.additional_data.picking_warehouses_id;
                    console.log(vm.user.pickupPointsSelected);
                }

                vm.checkWarehousesPermissions(vm.user.permissions);


                vm.showDialog = true;

                vm.getWarehouses();
                vm.getPickupPoints();

            }).catch( error => {
                console.log(error);
            });
        
       
        
        });
    },
    methods: {
        sendForm(){
            if( !this.$refs.form.validate() ) return;
            console.log('se deberia enviar el formulario');
            var vm = this;
            this.axios({
                url: 'admin/users/'+this.user_id,
                method: 'PUT',
                data: {...this.user, change_password: !this.lock_password ? 1 : 0}
            }).then( () => {
                vm.$store.dispatch('passport/fetchUser');
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        checkWarehousesPermissions(permissionsUser){
            var vm = this;
            vm.warehhousePicking = false;
            vm.pickupPointsPicking = false;
            // Programar para buscar el permiso de picking
            if (permissionsUser.includes(29)) {
                vm.warehhousePicking = true;
            }  
            if (permissionsUser.includes(31)) {
                vm.pickupPointsPicking = true;
            }  
           
                
        },
        getWarehouses(){
            var vm = this;
            vm.axios({
                url: 'admin/stores/'+vm.user.store_id+'/warehouses',
                method: 'GET'
            }).then( response => {
                console.log(response);
                response.data.data.forEach( e => {
                    vm.warehousesList.push({text: e.name, value: e.id});
                });
         
            });
        },
        getPickupPoints(){
            var vm = this;
            vm.axios({
                url: 'admin/stores/'+vm.user.store_id+'/pickuppoints',
                method: 'GET'
            }).then( response => {
                console.log(response);
                response.data.data.forEach( e => {
                    vm.pickupPointsPList.push({text: e.name, value: e.id});
                });
         
            });
        }
        
    },
    mounted()
    {
        var vm = this;
        this.axios({
            url: 'orders/stores/data_select_input',
            method: 'GET',
        }).then( response => {
            vm.storesList = response.data;
        }).catch( error => {
            console.log(error);
        })
    }
}
</script>